.branch-wrapper .profile-upper-pic img {
    max-height: 200px;
    width: 100%;
    height: 100%;
}

.profile-upper-pic {
    cursor: pointer;
}

.input-profile {
    opacity: 0;
    position: absolute;
    top: 0px;
    z-index: 99;
    width: 100%;
    height: 100%;
}

.input-profile-video {
    opacity: 0;
    position: absolute;
    top: 0px;
    z-index: 99;
    width: 100%;
    height: 73%;
    left: 0;
}

.profile-upper-pic {
    text-align: center;
    padding: 0;
    margin: 15px 0 0 0;
}

.addButton {
    background-color: transparent;
    border: 0;
}

.addButton img {
    width: 40px;
    height: 40px;
}

.borderLeft {
    border-left: 1px dashed #D1D1D1;
    border-image-slice: 2;
}


.workoutDays-tabs .nav-tabs {
    border: 0;
    justify-content: space-between;
    border-bottom: 1px solid #e9e6e6;
    transition:none !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.workoutDays-tabs .nav-tabs .nav-link{
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.workoutDays-tabs .nav-link {
    color: #bcbbbb;
    font-weight: 500 !important;
    border: 1px solid transparent;

}

.workoutDays-tabs .nav-link:hover {
    color: #000;
}

.workoutDays-tabs .nav-tabs .nav-item.show .nav-link,
.workoutDays-tabs .nav-tabs .nav-link.active ,
.workoutDays-tabs .nav-tabs .nav-link:hover {
    border-bottom: 1px solid #3e3d3d;
    isolation: unset !important;
    color: #000;
    outline: none;
    box-shadow: none;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}
.workoutDays-tabs .nav-tabs .nav-link:focus-visible,
.workoutDays-tabs .nav-tabs .nav-link:focus{
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    outline: none;
    box-shadow: none;
}


.add-wrokoutimg{
    width: 100%;
    border-radius:20px ;
    max-height: 300px;
    /* object-fit: cover; */
}
.profile-upper-pic.workout-upper-img{
    height: 180px;
}

@media screen and (max-width:991px) {
    .profile-pic-change {
        width: 50%;
        margin: auto;
    }
}

@media screen and (max-width:575px) {
    .profile-pic-change {
        width: 100%;
    }
}