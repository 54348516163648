.exercise-list-wrapper{
    max-height: 280px;
    overflow: hidden;
    overflow-y: scroll;
}

.profile-image{
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.heightOverflow{
    height: 100%;
}

.height100{
    height: 100%;
}

.exerciseImg{
    width: 100%;
    max-width: 50%;
    height: 80px;
    border-radius: 10px;
}  
.assign-wrapper-list{
    height: 90%;
    overflow: hidden;
    overflow-y: scroll;
}

.workoutDetilImg{
    max-height: 300px;
    border-radius: 20px;
    height: 100%;
    object-fit: contain;
}

.border10{
    border-radius: 10px;
}

.workoutDetailImage.training-demo{
    height: 80px;
    width: 130px;
    background-position:center;
    border-radius: 10px;
}

.no-assign{
    display:flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
@media screen and (max-width:1293px) {
    /* .exercise-list-wrapper {
        max-height: 300px;
    }     */
}
@media screen and (max-width:1025px) {
    /* .exercise-list-wrapper {
        max-height: 77vh;
    } */
    .heightOverflow{
        height: 100%;
    }
   
}
@media screen and (max-width:767px) {
    .responsiveGap{
        row-gap: 20px;
    }
    .responsiveGap .next-btn {
        padding: 6px 20px;
    }
}

@media screen and (max-width: 576px){
.responsiveGap .next-btn {
    width: auto;
    padding: 6px 20px;
}
.gap-4 {
    gap: 0.5rem!important;
}
.exerciseImg {
    max-width: 35%;
    height: 60px;
}
}