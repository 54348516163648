/* header-css */
.header-logo {
    max-width: 60%;
    height: auto;
  }
  .header-brand {
    max-width: 60%;
  }
  .nav-input-search {
    border: none;
    background-color: transparent;
    color: #bababa;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.32px;
    width: 400px;
    padding-left: 30px;
  }
  
  .nav-input-search:focus {
    border: none;
    background-color: transparent;
  }
  .cus-nav-input-search {
    border: 1px solid #000;
    background-color: #fff;
    color: #bababa;
    border-radius: 4px;
    padding: 5px 12px 5px 30px;
    width: 250px;
}
  .cus-nav-input-search:focus {
    border: 1px solid #000;
    background-color: #fff;
  }
  .custom-search-icon {
    position: absolute;
    top: 9px !important;
    left: 17px !important;
    max-width: 16px !important;
}

  .header-nav-item a {
    color: var(--secondary-400) !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding: 0px;
  }
  .header-nav-link {
    border-left: 1px solid #ddd;
  }
  .header-nav-item a.active {
    color: var(--primary-theme) !important;
  }
  .main-spacing {
    padding: 0px 10px;
  }
  .dask-link a {
    padding-left: 0px !important;
  }
  .search-icon {
    position: absolute;
    top: 7px;
    left: 10px;
    max-width: 5%;
}
  .header-icons {
    border-radius: 8px;
    border: 1px solid var(--secondary-200);
    text-align: center;
    padding: 12px;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  
  .header-img {
    max-width: unset;
    height: 20px;
    width: 20px;
}
  .straight-line {
    border: 0;
    border-top: 1px solid #f3f3f3;
    margin-top: 0.5rem;
  }
  .header-leads-btn {
    padding: 14px;
    padding-left: 15px;
    padding-right: 15px;
}
  .header-leads-btn::after {
    display: none;
}
.header-dropdown-menu[data-bs-popper] {
  right: 0 !important;
  left: unset !important;
}
.header-leads-btn:hover, .header-leads-btn:focus-visible{
  border-color: var(--secondary-200) !important;
}
.leads-btn:hover, .leads-btn:focus-visible{
  border-color: var(--secondary-200) !important;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  border-color:var(--secondary-200) !important;
}
.header-drop-items:active{
  background-color:var(--secondary-200) !important;
  color: var(--black) !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color:var(--secondary-200) !important;
  color: var(--black) !important;
}

.custom-range {
  display: flex;
  align-items: center;
  height: 1rem;
  width: 100%;
}

.custom-range input[type="range"] {
  -webkit-appearance: none;
  appearance: none; 
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 0px;
}

/* Track: webkit browsers */
.custom-range input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  background: #ccc;
  border-radius: 0px;
}

/* Thumb: webkit */
.custom-range input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none; 
  height: 5px;
  width: 10px;
  background-color: var(--primary-theme);
  border-radius: 0px;
  border: 2px solid var(--primary-theme);
  box-shadow: -407px 0 0 400px var(--primary-theme);
}


