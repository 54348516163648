@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Plus+Jakarta+Sans:wght@400;500;700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Sen:wght@400;700;800&display=swap");

:root {
  --font-family: "Plus Jakarta Sans", sans-serif;
  --primary-theme: #eb4d2a;
  --primary-500: #ed6214;
  --secondary-theme: #191919;
  --secondary-400: #757575;
  --secondary-300: #bababa;
  --secondary-200: #e7e7e7;
  --text-color: #191919;
  --error-600: #d82027;
  --success-600: #33b843;
  --black: #000;
  --white: #fff;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-color: #f9f9fb;
}

html,
body {
  overflow-x: hidden;
  font-family: var(--font-family);
}

img {
  margin: 0px;
  padding: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0px;
}

h1 {
  font-size: 34px;
}

h2 {
  font-size: 22px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

p {
  font-size: 12px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

a:focus-visible {
  outline: none;
}

ul {
  list-style-type: none;
  padding: 0px;
  margin-bottom: 0px;
}

.form-control:focus {
  box-shadow: unset;
}

.form-check-input:focus {
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.w-35 {
  width: 35%;
}

.w-55 {
  width: 55%;
}

.w-10 {
  width: 10%;
}

.text-gray {
  color: var(--secondary-400);
}

.red-text {
  color: #d40000;
}

.tabs-text {
  color: #b5b5b5;
}

.liColor {
  color: #767676;
}

.textColor {
  color: var(--secondary-theme);
}

.common-btnn {
  border-radius: 2.733px;
  background: var(--primary-500);
  border: 0;
  color: var(--white);
  padding: 10px 23px;
  font-weight: 600;
  font-size: 14px;
}

.f10-size {
  font-size: 9px;
}

.f11-size {
  font-size: 11px;
}

.f12-size {
  font-size: 10px;
}

.f14-size {
  font-size: 12px;
}

.f16-size {
  font-size: 14px;
}

.f18-size {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.theme-color {
  color: var(--primary-theme);
}

.common-bg {
  border-radius: 4px;
  background: var(--white);
  padding: 15px;
}

label {
  color: #151829;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -0.36px;
  text-transform: uppercase;
}

.common-input {
  border-radius: 11px !important;
  border: 1px solid #D1D1D1 !important;
  font-size: 14px;

}

.form-select:focus {
  outline: none;
  box-shadow: none;
}

.remark-textarea {
  resize: none;
}

.green-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: var(--success-600);
  border-radius: 50%;
  position: relative;
}

.red-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background-color: var(--error-600);
  border-radius: 50%;
  position: relative;
}

.green-blink {
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--success-600);
  opacity: 0.7;
  border-radius: 50%;
  animation: green-blink 1s linear infinite;
}

.red-blink {
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--error-600);
  opacity: 0.7;
  border-radius: 50%;
  animation: red-blink 1s linear infinite;
}

.navbar-toggler:focus {
  box-shadow: unset !important;
}

@keyframes green-blink {
  100% {
    transform: scale(2, 2);
    opacity: 0;
  }
}

@keyframes red-blink {
  100% {
    transform: scale(2, 2);
    opacity: 0;
  }
}

.form-height {
  min-height: calc(100vh - 223.19px);
  height: auto;
}

.form-select {
  cursor: pointer;
}


.dollar-Sign {
  position: absolute;
  left: 10px;
  z-index: 99;
  top: 5px;
}

.dollarInput {
  padding-left: 30px !important;
}

.search-header {
  border: 1px solid #000000;
  border-radius: 10px;
}

.header-branch {
  padding: 13px 35px 14px 16px !important;
  border: 1px solid #e7e7e7 !important;
}

.css-165mck1 {
  width: 100%;
}