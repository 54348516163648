
/* / carousel-css view-modal / */
.contain {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.item {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
button.owl-prev {
  position: absolute;
  top: 50%;
  left: -25%;
  border-radius: 50% !important;
  padding: 5px 20px !important;
  font-size: 25px !important;
  text-align: center;
}

button.owl-next {
  position: absolute;
  top: 50%;
  right: -25%;
  border-radius: 50% !important;
  padding: 5px 20px !important;
  font-size: 25px !important;
  text-align: center;
}
.custom-nav-btn img{
  max-width: 60%;
}
.owl-theme .owl-nav [class*='owl-']:hover {
  background: transparent !important;
}

/* add-customer-modal  and  send-message*/
.file-select {
  width: 100%;
  height: 150px;
  border-radius: 4px;
  background: #fafafb;
}
.upload-file {
  width: 100%;
  height: 150px;
  opacity: 0;
  cursor: pointer;
  position: relative;
  z-index: 9999;
}
.file-img {
  position: absolute;
  top: 0;
  cursor: pointer;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;
}
.file-format-text{
  color: #898989;
  font-size: 14px;
  font-style: italic;
  line-height: 21px;
  letter-spacing: -0.28px;
}
.file-text{
  color: #151829;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.32px;
}
.customer-modal-text{
  color: var(--secondary-theme);
  font-style: normal;
  line-height: 36px;
  letter-spacing: -0.48px;
  font-size: 20px;
}
.next-submit-btn {
  padding: 10px 32px;
  border-radius: 4px;
  background: var(--primary-500);
  border: 0;
  color: var(--white);
  font-weight: 600;
  font-size: 14px;
  width: 100%;
}
