/* Login-style */
.login-head {
    background-image: url("./../images/login_bg.png");
    background-repeat: no-repeat;
    background-position: 100% 36%;
    background-size: cover;
    position: relative;
    z-index: 0;
  }
  .form-logo {
    padding-top: 3rem;
    padding-bottom: 0.25rem;
  }
  
  .login-form-head {
    background-color: var(--white);
    margin-top: 7rem;
    width: 39%;
    box-shadow: 0px 5px 15px 0px rgba(76, 57, 47, 0.1);
    position: relative;
  }
  .login-form-head::before {
    content: url("./../images/login_square.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: -50px;
    left: -56px;
    z-index: -1;
  }
  .login-form-head::after {
    content: url("./../images/login_circle.svg");
    background-repeat: no-repeat;
    position: absolute;
    top: -50px;
    right: -56px;
    z-index: -1;
  }
  .form-head-text {
    color: var(--text-color);
    font-family: var(--font-family);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 54px;
    letter-spacing: -1.08px;
  }
  .Login-text {
    color: var(--secondary-400);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.36px;
  }
  .input-label {
    color: var( --secondary-theme);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.28px;
  }
  .input-field {
    color: var(--secondary-300);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.28px;
    padding: 16px 20px;
    border-radius: 4px;
    border: 1px solid var(--secondary-200);
  }
  .err_msg {
    color: red;
    font-size: 10px;
    margin-left: 2px;
  }
  .password-field {
    padding: 16px 36px 16px 20px;
  }
  .eye-icon {
    position: absolute;
    top: 52px;
    right: 15px;
  }
  .Sign-btn {
    border-radius: 4px;
    background: #151829;
    height: 60px;
    width: 100%;
    text-align: center;
    color: var(--white);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
  .login-logo {
    max-width: 100%;
    height: auto;
  }
  .sign-in-head {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .footer-text{
    color:var(--secondary-300);
    font-size:14px;
    font-style:normal;
    font-weight:400;
    margin-top:7rem;
    }