.addbtn {
  padding: 7px 12px;
}

.attendence-table-head {
  background: rgba(21, 24, 41, 0.1) !important;
  border-right: 10px solid #f9f9fb;
}

.attendence-table-data {
  border: 10px solid #f9f9fb;
  background-color: #fff !important;
  height: 210px;
  width: 100%;
  min-width: 200px;
}

.attendence-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: none;
  object-fit: cover;
}

.attendance-name {
  color: #191919;
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
}

.attendance-text {
  color: #767676;
  text-align: center;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  letter-spacing: -0.32px;
}

.shift-timing {
  color: #eb4d2a;
  font-size: 14px;
  font-weight: 400;
  background: rgba(235, 77, 42, 0.1);
  padding: 10px;
}

.timing-in {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.24px;
  border-radius: 2px;
  padding: 5px;
}
.half-timing{
  color : #29AEC5 !important
}

.full-timing{
  color : #189E2D !important
}

.timing-out {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.24px;
  border-radius: 2px;
  padding: 5px;
}

.timings-data {
  display: inline-flex;
  flex-direction: column;
}

.timing-edit {
  position: absolute;
  top: -6px;
  right: -1px;
}

.timing-copy {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.full-day-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color:#fff;
  font-size: 14px;
  font-weight: 400;
  padding: 6px;
}
.half-day{
  background: #29AEC5 !important
}
.leave-day{
  background: #eb4d2a !important
}
.full-day{
  background: #189E2D !important
}
.half-count{
  padding: 5px 9px;
  color: #29AEC5;
  background-color:#E6F8FB ;
}
.full-count{
  padding: 5px 9px;
  color: #189E2D;
  background-color:#E6F8FB ;

}
.leave-count{
  padding: 5px 9px;
  color: #eb4d2a;
  background-color:#FBEDE6 ;

}
.half-color{
  background-color: #E6F8FB !important;
}
.leave-color{
  background-color: #FBEDE6 !important;
}
.full-color{
  background-color: #E8F6EA !important;
}
.half-day-text {
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: #eb4d2a;
  font-size: 14px;
  font-weight: 400;
  background: rgba(235, 77, 42, 0.1);
  padding: 6px;
}

.mark-text {
  color: #ed6214;
  font-size: 12px;
  font-weight: 400;
  /* margin-top: 70px; */
}

.calender-bg {
  background: #efefef;
  border-radius: 0px;
  border: none;
  color: #757575;
  text-align: center;
  font-size: 16px;
}

.month-selector {
  background-color: #efefef;
  width:100px
}

.arrow-button {
  background: none;
  border: none;
  color: #888;
}

.month-label {
  color: #666;
  margin: 0 16px;
}

.attendence-table-head {
  padding: 10px;
  text-align: left;
}

.attendence-table-head {
  border-top: unset !important;
  padding-top: unset !important;
}

.attendence-table {
  border-collapse: unset !important;
  width: 100%!important;
  height: 100%!important; 
}

.attendence-table-data {
  border-left: unset !important;
  padding-left: unset !important;
}

.select-dropdown {
  border: none !important;
  background-color: #efefef;
  padding: 8px 12px;
}
.sticky-head th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #e2e2e5 !important;
}
tbody th,
tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.sticky-head th:first-child {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: #e2e2e5 !important;
}

.vertical-heading {
  width: 100%!important;
}

.mark-text {
  display: block;
  color: #ed6214;
  font-size: 12px;
  /* margin-top: 70px; */
  margin-bottom: 15px;
}


.mark-text input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.mark-text label {
  position: relative;
  cursor: pointer;
}

.mark-text label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 0.5px solid #ED6214;
  background: #FBEDE6;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 5px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 2px;
  margin-bottom: 2px;
}

.mark-text input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 8px;
  left: 3px;
  width: 5px;
  height: 8px;
  border: solid  #ED6214;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox-label {
  color: unset;
  text-transform: capitalize;
  font-weight: unset;
  font-size: unset;
}

.checkout-heading{
    display: flex;
    justify-content: space-between;
    margin-top: 19px;
    margin-left: 31px;
}