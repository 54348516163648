/* footer */
.footer-main {
    background: #151829;
    padding: 21px 0px;
    bottom: 0;
    position: relative;
    width: 100%;
  }
  
  .footer-content {
    color: var(--white);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.28px;
  }
  