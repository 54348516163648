.add-customer-text {
    color: var(--secondary-theme);
    line-height: 36px;
    letter-spacing: -0.48px;
}
input[type="date"] {
    width: 200px; /* Set the width as desired */
  }
.next-btn {
    padding: 10px 32px;
    border-radius: 4px;
    background: var(--primary-500);
    border: 0;
    color: var(--white);
    font-weight: 600;
    font-size: 14px;
}

.err_msg {
    color: red;
    font-size: 10px;
    margin-left: 2px;
  }
.basic-info{
    background: url("../images/fitx-line.svg");
    width: 100%;
    height:4px;
    background-size: cover;
    position: relative;
}
.basic-head {
    position: absolute;
    background-color: #fff;
    bottom: -7px;
    padding: 0 13px 0 0px;
    color: #A5A5A5;
}
.time-input {
    width: 45%;
}
.mobile-number{
font-size: 10px;
}
.disabled-button {
    cursor: not-allowed; 
  }

  .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root {
    width: 100%;
    border: 0 !important;
  
}
.MuiInputBase-root {
    height: 34px;
    border-radius: 11px !important;
    font-size: 14px;
}
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root{
    line-height: 0 !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 1px solid #D1D1D1 !important;

}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #D1D1D1 !important;
}
.disabledbtn-custom{
    background-color: #e9ecef;
    border-radius: 11px !important;
}
.leads-listing {
    max-height: 500px;
    overflow-y: scroll;
}
.staff-list {
    max-height: 250px;
    overflow-y: scroll;
}
.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #d1d1d1 !important;
}
